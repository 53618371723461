<template>
    <div class="input-group">
        <!-- TODO: wanneer textarea gevuld is moet de active class er op blijven staan. -->
        <textarea class="input-group__input" v-model="remarks" :id="`textarea-${aspectNumber}`" rows="1" :class="{ 'active': isActive }" :disabled="state === 'step2'"/>

        <label :for="`textarea-${aspectNumber}`">
           Opmerking:
        </label>
    </div>
</template>

<script>
    export default {
        name: 'autogrow-textarea',
        props: {
            value: String,
            state: {type: String, required: true},
            aspectNumber: {type: Number, required: true}
        },
        data() {
            return {
                active: false
            }
        },
        methods: {
            selectInput() {
                this.active = !this.active;
            },
        },
        computed: {
            remarks: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('change-remarks', val);
                }
            },
            isActive() {
                return this.active || typeof this.remarks !== 'undefined';
            }
        },
        emits: ['change-remarks'],
        mounted() {
            this.$el.querySelector('textarea').addEventListener('keydown', (event) => {
                const el = event.target;
                setTimeout(() => {
                    el.style.cssText = 'height:auto; padding:0';
                    el.style.cssText = `height:${el.scrollHeight}px`;
                },0);
            });
        }
    }
</script>