<template>
    <div>
        <h2 class="text-secondary">{{ theme.name }}</h2>

        <!-- Steps -->
        <div class="step-navigation">
            <div class="progress progress-bar-vertical step-navigation__progress">
                <div aria-valuemax="100" aria-valuemin="50" aria-valuenow="100" class="progress-bar" role="progressbar" style="width: 100%;">
                    <span class="sr-only">50% Compleet</span>
                </div>
            </div>

            <ul class="step-navigation__inner">
                <li class="step disabled">
                    <div class="d-flex flex-row justify-content-start align-items-center mb-2">
                        <div class="step__icon"><span><icon icon="pin"/></span></div>
                        <h4 class="step__title">stap 1</h4>
                    </div>

                    <div class="step__content">
                        <p>Bepalen huidige situatie en gewenste situatie.</p>
                    </div>
                </li>

                <li class="step">
                    <div class="d-flex flex-row justify-content-start align-items-center mb-2">
                        <div class="step__icon"><span><icon icon="clipboard"/></span></div>
                        <h4 class="step__title">stap 2</h4>
                    </div>

                    <div class="step__content">
                        <p>Keuze van de aspecten.</p>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row mb-5">
            <div class="col-12 col-lg-8 order-1 order-lg-0">
                <div v-html="content"></div>
            </div>

            <div class="col-12 col-lg-5 order-0 order-lg-1"></div>
        </div>

        <!-- Aspect -->
        <div class="aspects">
            <ScanAspect :aspect="aspect" :key="aspect.slug" v-for="(aspect, index) in aspects.all()" :itemIndex="index" state="step2" />
        </div>

        <!-- Button vorige & volgende -->
        <div class="main__content__navi d-flex justify-content-between align-content-center my-5 py-5">
            <a href="#" class="btn btn-outline-secondary mr-3" @click="prevStep($event)">
                <span><icon icon="arrow-left"/></span>
                Vorige
            </a>

            <printButton class="ml-auto mr-3" />

            <a href="#" class="btn btn-secondary" @click="nextStep($event)">
                Bekijk gekozen aspecten
                <span><icon icon="arrow-right"/></span>
            </a>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ScanAspect from '@/components/ScanAspect.vue'
    import Icon from "@/components/Icon";
    import api from "../../data/api";
    import AspectsCollection from '../../collections/AspectsCollection';
    import PrintButton from "@/components/PrintButton";

    export default {
        name: 'Home',
        components: {
            ScanAspect,
            Icon,
            PrintButton
        },
        computed: {
            content() {
                if (typeof this.theme?.analysesContent !== 'undefined') {
                    return this.theme.analysesContent.step2
                }
                
                return null;
            }
        },
        methods: {
            prevStep (event){
                event.preventDefault();

                this.$router.push({ 
                    name: 'analyse-scan',
                    params: { 
                        thema: this.theme.slug, 
                    }
                });
            },
            nextStep (event){
                event.preventDefault();

                this.$router.push({ 
                    name: 'interventie-thema',
                    params: { 
                        thema: this.theme.slug, 
                    }
                });
            }
        },
        mounted() {
            api.fetchTheme(this.$route.params.thema).then(response => {
                this.theme = response;

                api.fetchAspects(this.theme.id).then(response => {
                    this.aspects = response;
                });

                const breadcrumbs = [
                    {
                        'label': 'Home',
                        'path': '/'
                    },
                    {
                        'label': 'Analyse',
                        'path': '/analyse/'
                    },
                    {
                        'label': this.theme.pageTitle,
                        'path': `/analyse/${this.theme.slug}/`
                    },
                    {
                        'label': 'Stap 2',
                    },
                ];
                this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
                this.$store.commit('SET_BREADCRUMBS_BUTTONS', {print: true});
            });
        },
        data() {
            return {
                pageTitle: 'Scan',
                theme: {},
                aspects: new AspectsCollection()
            }
        },
        created() {
            this.theme = this.$store.getters.getThemeBySlug(this.$route.params.thema);
        },
    }
</script>
